import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MediaCard from './MediaCard';
import './App.css';

function Content() {
  const [files, setFiles] = useState([], {});
  const [accessToken, setAccessToken] = useState('', {});
  const [nextPageToken, setNextPageToken] = useState('', {});
  const [orderBy, setOrderBy] = useState('createdTime desc', {});
  const [query, setQuery] = useState('', {});
  const [searchType, setSearchType] = useState('tag', {});
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setAccessToken(tokenResponse.access_token);
      console.log(tokenResponse);
      const token = await getFileList(tokenResponse.access_token);
      setNextPageToken(token);
    },
    scope: 'https://www.googleapis.com/auth/drive.readonly',
  });

  const getFileList = async (accessToken, nextPageToken) => {
    const returnQ = (query) => {
      if (query) return "mimeType contains 'video/' and " + query;
      return "mimeType contains 'video/'";
    };
    const res = await axios.get('https://www.googleapis.com/drive/v3/files', {
      params: {
        orderBy: searchType === 'tag' ? orderBy : null,
        fields: 'files,nextPageToken',
        pageToken: nextPageToken,
        q: returnQ(query),
      },
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    setFiles(files.concat(res.data.files));
    console.log(res.data);
    return res.data.nextPageToken;
  };

  const proceedPage = async () => {
    const token = await getFileList(accessToken, nextPageToken);
    setNextPageToken(token);
  };

  const handleModeChange = (event, newMode) => {
    setOrderBy(newMode);
    setNextPageToken('');
    setFiles([]);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    if (event.target.value) {
      setOrderBy('name desc');
    }
    setNextPageToken('');
    setFiles([]);
  };

  const gigaTag = [
    'csft',
    'dhry',
    'ghkr',
    'ghls',
    'ghnu',
    'ghov',
    'giga',
    'gigp',
    'giro',
    'gomk',
    'gvrd',
    'jmsz',
    'spsa',
    'spsb',
    'spsc',
  ];
  const tagToQuery = (arr) =>
    arr.map((item) => `name contains '${item}'`).join(' or ');

  return (
    <div className="App">
      <header className="App-header">
        <Button variant="contained" onClick={() => login()}>
          Sign in with Google 🚀{' '}
        </Button>
        <Box sx={{ p: 1 }} />
        <ToggleButtonGroup
          size="large"
          value={orderBy}
          exclusive
          onChange={handleModeChange}
        >
          <ToggleButton value="createdTime desc">新しい順</ToggleButton>
          <ToggleButton value="createdTime">古い順</ToggleButton>
          <ToggleButton value="name">名前昇順</ToggleButton>
          <ToggleButton value="name desc">名前降順</ToggleButton>
          <ToggleButton value="starred">スター</ToggleButton>
        </ToggleButtonGroup>
        <Box sx={{ p: 1 }} />
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => {
              setSearchType(e.target.value);
              setQuery('');
            }}
            defaultValue="tag"
          >
            <FormControlLabel value="tag" control={<Radio />} label="Tag" />
            <FormControlLabel
              value="description"
              control={<Radio />}
              label="Description"
            />
          </RadioGroup>
        </FormControl>
        <Box sx={{ minWidth: 120 }}>
          {searchType === 'tag' ? (
            <FormControl fullWidth>
              <InputLabel id="query-select-label">Query</InputLabel>
              <Select
                labelId="query-select-label"
                id="query-select"
                value={query}
                label="Query"
                onChange={handleQueryChange}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="name contains 'fc2'">FC2</MenuItem>
                <MenuItem value="not name contains 'fc2'">FC2以外</MenuItem>
                <MenuItem value="name contains 'fnk'">FNK</MenuItem>
                <MenuItem value="name contains 'ktb'">KTB</MenuItem>
                <MenuItem value="name contains 'ktsg'">KTSG</MenuItem>
                <MenuItem value="name contains 'ktsb'">KTSB</MenuItem>
                <MenuItem value="name contains 'ktft'">KTFT</MenuItem>
                <MenuItem value="name contains 'abp'">ABP</MenuItem>
                <MenuItem value="name contains 'arm' or name contains 'aarm' or name contains 'parm'">
                  ARM
                </MenuItem>
                <MenuItem value="name contains 'fch'">FCH</MenuItem>
                <MenuItem value="name contains 'clot'">CLOT</MenuItem>
                <MenuItem value="name contains 'bban'">BBAN</MenuItem>
                <MenuItem value="name contains 'ssni'">SSNI</MenuItem>
                <MenuItem value="name contains 'juy'">JUY</MenuItem>
                <MenuItem value="name contains 'ipx'">IPX</MenuItem>
                <MenuItem value="name contains 'flav'">FLAV</MenuItem>
                <MenuItem value="name contains 'fset' or name contains 'akdl'">
                  AKNR
                </MenuItem>
                <MenuItem value="name contains 'okk'">OKK</MenuItem>
                <MenuItem value="name contains 'sqte'">SQTE</MenuItem>
                <MenuItem value={tagToQuery(gigaTag)}>GIGA</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <TextField
              id="outlined-basic"
              label="Word"
              variant="outlined"
              onChange={(e) => {
                setQuery(`fullText contains '${e.target.value}'`);
                setNextPageToken('');
                setFiles([]);
              }}
            />
          )}
        </Box>
      </header>
      <MediaCard files={files} />

      <Button onClick={proceedPage} disabled={!nextPageToken && files.length}>
        <ArrowCircleRightIcon style={{ height: '150px', width: '150px' }} />
      </Button>
    </div>
  );
}

export default Content;
